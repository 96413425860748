import "./home.scss";

import React, { Component } from "react";
import Webcam from "react-webcam";
import Carousel from "react-bootstrap/Carousel";
import Stabbing from "../../assets/STABBING.gif";
import Playa from "../../assets/playa-drift-3.gif";
import Braindead from "../../assets/braindead.gif";

// import {
//   Link
// } from "react-router-dom";

const slides = [Stabbing, Playa, Braindead];

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "DATABASE---MINDPALACE.COM",
            titles: [
                "DATABASE---MINDPALACE.COM",
                "#CREATORSONLY",
                "#KILLALLARTISTS",
                "© HUMANO CREANDO",
                "KAA MOVEMENT ™",
            ],
        };
    }

    componentDidMount() {
        let idx = -1;

        setInterval(() => {
            this.setState({
                title: this.state.titles[idx + 1],
            });

            idx = idx === 3 ? 0 : idx + 1;
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval();
    }

    render() {
        return (
            <div className="container-fluid">
                <article className="home text-center">
                    <Webcam />
                    <div className="home-main">
                        <div className="title">
                            <p>{this.state.title}</p>
                        </div>
                    </div>
                    <div className="loop follow">
                        <div className="marquee">
                            <span>
                                DATABASE---MINDPALACE ® - HUMANO CREANDO - GO
                                FOLLOW
                                <a
                                    href="https://www.instagram.com/DATABASEMINDPALACE____STUDIO/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {" "}
                                    /DATABASEMINDPALACE____STUDIO{" "}
                                </a>
                            </span>
                        </div>
                        <div className="marquee marquee2">
                            <span>
                                DATABASE---MINDPALACE ® - HUMANO CREANDO - GO
                                FOLLOW
                                <a
                                    href="https://www.instagram.com/DATABASEMINDPALACE____STUDIO/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {" "}
                                    /DATABASEMINDPALACE____STUDIO{" "}
                                </a>
                            </span>
                        </div>
                        <div className="marquee marquee3">
                            <span>
                                DATABASE---MINDPALACE ® - HUMANO CREANDO - GO
                                FOLLOW
                                <a
                                    href="https://www.instagram.com/DATABASEMINDPALACE____STUDIO/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {" "}
                                    /DATABASEMINDPALACE____STUDIO{" "}
                                </a>
                            </span>
                        </div>
                    </div>
                </article>
                <article className="post main">
                    <div className="photo post-bg tile text-center d-flex flex-column">
                        <Carousel
                            indicators={false}
                            controls={false}
                            slide={false}
                        >
                            {slides.map((slide) => {
                                return (
                                    <Carousel.Item interval={500}>
                                        <img
                                            className="d-block w-100"
                                            src={slide}
                                            alt={`${slide}`}
                                        />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                        <p className="text-white mt-5">
                            MARTIN MINTEGUIAGA ™ AKA THAT BOI $KINNY AKA
                            DATABASES IS A DEVELOPER AND LO-FI ARTIST BASED IN
                            BUENOS AIRES. THIS IS HIS MIND PALACE
                        </p>
                    </div>
                </article>
                <article className="post main works">
                    <div className="photo post-bg tile text-center">
                        <ul>
                            <li>
                                <a
                                    href="https://www.instagram.com/ar/633761293800539/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    IRIDESCENSE +
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.fxhash.xyz/u/database"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    NFTS FROM THAT BOI $KINNY
                                </a>
                            </li>
                            <li>
                                <a
                                    href="http://galeriamimente.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    GALERIA MI MENTE
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://collectiveai.io/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    COLLECTIVE AI
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://calculadora-ganancias.tn.com.ar/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    INCOME TAX CALCULATOR - TN
                                </a>
                            </li>
                        </ul>
                    </div>
                </article>
                <footer>
                    <span className="text-center">
                        © HUMANO CREANDO - ALL RIGHTS RESERVED - THE DATABASES
                        CREATIVE TEAM (R)
                    </span>
                </footer>
            </div>
        );
    }
}

export default Home;
