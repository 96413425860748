import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/home/home";
// import Palace from '../pages/palace/palace';
// import Gang from '../pages/gang/gang';
import ScrollToTop from "../components/scroll.js";

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/"
                >
                    <ScrollToTop />
                    <Home />
                </Route>

                {/* <Route exact path="/databasesmindpalace">
					<ScrollToTop />
					<Palace />
				</Route>

				<Route exact path="/lolifegang">
					<ScrollToTop />
					<Gang />
				</Route> */}

                <Route>
                    <ScrollToTop />
                    <Home />
                </Route>
            </Switch>
        );
    }
}

export default Routes;
